import React from "react";
var __jsx = React.createElement;
import classnames from "classnames";
import { IconStyled } from "./styles";
export const Icon = _ref => {
  let {
    children,
    className,
    color = "inherit",
    size = "24px"
  } = _ref;
  return __jsx(IconStyled, {
    $color: color,
    $size: size,
    className: classnames("material-icons", className),
    "data-qa": "Icon-IconStyled"
  }, children);
};
Icon.displayName = "Icon";