import { graphql, readInlineData } from "react-relay";

import { checkSchemaNodeHasScmpYoutubeVideo } from "scmp-app/components/schema-render/common/iframe/helpers";
import type { helpersIsArticleVideoAsHeroArticle$key } from "scmp-app/queries/__generated__/helpersIsArticleVideoAsHeroArticle.graphql";

export const isArticleVideoAsHero = (reference: helpersIsArticleVideoAsHeroArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersIsArticleVideoAsHeroArticle on Article @inline {
        multimediaEmbed {
          json
        }
      }
    `,
    reference,
  );

  return (
    article?.multimediaEmbed?.json?.some(node => checkSchemaNodeHasScmpYoutubeVideo(node)) ?? false
  );
};
