import styled from "@emotion/styled";

import ArticleIcon from "./icon-article.svg";
import GalleryIcon from "./icon-gallery.svg";
import InfographicIcon from "./icon-infographic.svg";
import LongReadIcon from "./icon-long-read.svg";
import OpinionIcon from "./icon-opinion.svg";
import PodcastIcon from "./icon-podcast.svg";
import VideoIcon from "./icon-video.svg";

export const ContentTypeContainer = styled.div`
  margin-inline-start: 8px;
`;

export const StyledArticleIcon = styled(ArticleIcon)``;

export const StyledGalleryIcon = styled(GalleryIcon)``;

export const StyledInfographicIcon = styled(InfographicIcon)``;

export const StyledLongReadIcon = styled(LongReadIcon)``;

export const StyledOpinionIcon = styled(OpinionIcon)``;

export const StyledPodcastIcon = styled(PodcastIcon)``;

export const StyledVideoIcon = styled(VideoIcon)``;
