import deepmerge from "deepmerge";
import { useAtomValue, useSetAtom } from "jotai";
import qs from "qs";
import { useCallback, useEffect } from "react";
import useSWRImmutable from "swr/immutable";

import { config } from "shared/data";
import { accountAtom } from "shared/lib/account/atoms";

import { useSnackbarSimple } from "scmp-app/components/common/snackbar/hooks";
import { bookmarkAtom } from "scmp-app/components/header/header-bookmark/atoms";
import { useLoginDialogStateHelper } from "scmp-app/components/login-dialog/hooks";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { Event as GA4Event } from "scmp-app/components/tracking/google-analytics-4/types";
import type { UntypedGA4Event } from "scmp-app/components/tracking/google-tag-manager/types";
import { useDeferredAction } from "scmp-app/lib/hooks";

import { create, fetchList } from "./apis";
import { userBookmarkAtom } from "./atoms";
import { BookmarkedContentListSwrFetchKey } from "./consts";
import { StyledSnackbarAction, StyledSnackbarMessage } from "./styles";
import type { BookmarkLocation, BookmarkPayload, BookmarkQueryStringPayload } from "./types";
import { generateNewList } from "./utils";

export const useUserBookmarkInit = () => {
  const { isLoggedIn } = useAtomValue(accountAtom);
  const setUserBookmarkState = useSetAtom(userBookmarkAtom);

  const { data, mutate } = useSWRImmutable(
    isLoggedIn ? BookmarkedContentListSwrFetchKey : null,
    fetchList,
    {
      refreshInterval: 1000 * 30,
      revalidateOnMount: true,
    },
  );

  useEffect(() => {
    if (!data || !mutate) return;
    setUserBookmarkState(current => ({ ...current, data, mutate }));
  }, [data, mutate, setUserBookmarkState]);
};

export const useBookmark = () => {
  const { isLoggedIn } = useAtomValue(accountAtom);
  const { data, mutate } = useAtomValue(userBookmarkAtom);
  const { deferRun } = useDeferredAction([!!data, !!mutate]);
  const { handleOpenSnackbar } = useSnackbarSimple();
  const { openLoginDialog } = useLoginDialogStateHelper();
  const { toggleBookmarkRippleEffect, toggleShowNewBookmarkAnimation } = useAtomValue(bookmarkAtom);
  const handleBookmarkAction = useCallback(
    <Untyped extends false | true>(
      payload: BookmarkPayload,
      trackings?: {
        ga4Events?: Record<"click", Untyped extends true ? GA4Event : UntypedGA4Event>;
        location?: BookmarkLocation;
      },
      callback?: () => void,
    ) => {
      const ga4ActionType = { bookmark: "add", unbookmark: "remove" }[payload.action];
      if (trackings?.ga4Events?.click) {
        sendGA4Tracking<true>(
          deepmerge(trackings.ga4Events.click as UntypedGA4Event, {
            customized_parameters: {
              action_type: ga4ActionType,
            },
          }),
          {
            untyped: true,
          },
        );
      }

      if (isLoggedIn) {
        if (!mutate) {
          return;
        }
        deferRun(async () => {
          await mutate(data => create(data, payload), {
            optimisticData: data => generateNewList(data, payload),
            populateCache: true,
            revalidate: false,
            rollbackOnError: true,
          });

          if (trackings?.ga4Events?.click) {
            sendGA4Tracking<true>(
              deepmerge(trackings.ga4Events.click as UntypedGA4Event, {
                action: "sys",
                customized_parameters: {
                  action_type: ga4ActionType,
                },
              }),
              {
                untyped: true,
              },
            );
          }

          const snackbarProps = {
            duration: 4000,
            leftElement: (
              <StyledSnackbarMessage>
                {payload.action === "bookmark" ? "Bookmarked" : "Unbookmarked"}
              </StyledSnackbarMessage>
            ),
            rightElement: (
              <StyledSnackbarAction pathname={`${config.account.scmpAccountHost}/manage/bookmark`}>
                View all
              </StyledSnackbarAction>
            ),
          };
          handleOpenSnackbar(snackbarProps);

          if (payload.action === "bookmark") {
            toggleBookmarkRippleEffect();
            toggleShowNewBookmarkAnimation(true);
          }
          callback?.();
        });
      } else {
        const queryStringPayload: BookmarkQueryStringPayload = {
          action: {
            bookmark: payload.id,
          },
        };
        const accountUrl = new URL(window.location.href);
        accountUrl.search = qs.stringify(queryStringPayload);
        openLoginDialog({
          description: "Login or register to save your favourite articles.",
          destination: accountUrl.toString(),
          ga4CustomParameter: {
            trigger_point: "bookmark",
          },
          title: "Bookmark this story",
        });
      }
    },
    [
      deferRun,
      handleOpenSnackbar,
      isLoggedIn,
      mutate,
      openLoginDialog,
      toggleBookmarkRippleEffect,
      toggleShowNewBookmarkAnimation,
    ],
  );

  const checkIsBookmarked = useCallback(
    (entityUuid: string) => data?.includes(entityUuid) ?? false,
    [data],
  );

  return {
    checkIsBookmarked,
    handleBookmarkAction,
  };
};
