import type { FunctionComponent } from "react";

import type { getContentTypeName } from "scmp-app/components/content/helpers";

import {
  ContentTypeContainer,
  StyledArticleIcon,
  StyledGalleryIcon,
  StyledInfographicIcon,
  StyledLongReadIcon,
  StyledOpinionIcon,
  StyledPodcastIcon,
  StyledVideoIcon,
} from "./styles";

const getIcon = (contentTypeName: ReturnType<typeof getContentTypeName>) => {
  switch (contentTypeName) {
    case "article":
      return <StyledArticleIcon />;
    case "gallery":
      return <StyledGalleryIcon />;
    case "infographic":
      return <StyledInfographicIcon />;
    case "long read":
      return <StyledLongReadIcon />;
    case "opinion":
      return <StyledOpinionIcon />;
    case "podcast":
      return <StyledPodcastIcon />;
    case "video":
      return <StyledVideoIcon />;
  }
};

type Props = {
  contentTypeName: ReturnType<typeof getContentTypeName>;
};

export const ContentTypeIcon: FunctionComponent<Props> = ({ contentTypeName }) => (
  <ContentTypeContainer>{getIcon(contentTypeName)}</ContentTypeContainer>
);

ContentTypeIcon.displayName = "ContentTypeIcon";
